<template>
  <form>
    <columns class="is-centered">
      <column class="is-5">
        <div class="is-flex is-align-items-center is-justify-content-center">
          <img class="guest-logo" src="/img/inspeckta-logo-white.svg" width="100" height="100" alt="inspeckta-logo">
        </div>
        <h2 class="title is-3 has-text-weight-light has-text-centered mb-6">Reset Your Password</h2>
        <columns>
          <column>
            <text-input classes="is-medium is-rounded" required :value="user.email" @input="updateEmail">
              Email Address
            </text-input>
          </column>
        </columns>
        <columns>
          <column>
            <submit-button class="is-medium mt-2 is-rounded is-fullwidth" :working="working" @submit="submitReset">
              Submit
            </submit-button>
          </column>
        </columns>
        <columns>
          <column>
            <router-link class="is-flex" :to="{ name: 'signin' }">
              <icon icon="arrow-left" />
              <span>Back to Sign In</span>
            </router-link>
          </column>
        </columns>
      </column>
    </columns>
  </form>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { auth as backend } from '@/api'

export default {

  data: () => ({
    working: false
  }),

  methods: {
    ...mapMutations('auth', [
      'updateEmail',
      'updatePassword'
    ]),
    submitReset() {
      this.working = true
      if(!this.user.email) {
        this.working = false
        return this.$toast.error('Please enter your email address')
      }
      backend.forgotPassword({
        email: this.user.email
      }, () => {
        this.$router.push('/')
        this.$toast.info('If an account with the associated email address exists, you will be notified with a link to reset your password.')
        this.working = false
      }, error => {
        if (error.response.status === 429) {
          this.$alert({
            title: 'Threat Detected',
            message: 'Our systems have flagged your activity as suspicious. Please contact support for assistance.'
          })
        }
        this.working = false
      })
    }
  },

  computed: {
    ...mapGetters('auth', [
      'user'
    ])
  }

}
</script>